import React from "react"

import Index from "../components/index"
import Seo from "../components/other/seo"
import Container from "../components/layout/container";
import {
  Link,
} from "gatsby";

const TermsOfUse = () => (
  <Index>
    <Seo title="Terms of Use" />
    <Container>
      <h1 className="text-blue">InCircle Terms of Use</h1>
      <p>
        The InCircle, LLC ("InCircle") website is made available to you conditioned on your acceptance, without modification, of the terms, conditions, and notices contained on this page. You agree to be bound by these terms, conditions, and notices, and indicate such agreement by your use of any InCircle website.
      </p>
      <p>
        InCircle reserves the right to change the terms under which this site is made available.
      </p>
      <p>
        <b>Copyright and Trademark Notices</b>
      </p>
      <p>
        All contents of the InCircle website, other than visitor Submissions (as defined below), are: Copyright © 2020 InCircle, LLC. All rights reserved.
      </p>
      <p>
        The InCircle name and logo, and the Nufactor name and logo, are either trademarks or registered trademarks of InCircle and all products names identified by ®, (™), or (℠) symbols or appearing in type form different from that of the surrounding text are trademarks owned by or licensed to InCircle, LLC, its subsidiaries or affiliates, or another entity.
      </p>
      <p>
        Any rights not expressly granted herein are reserved.
      </p>
      <p>
        <b>Personal and Non-Commercial Use Limitation</b>
      </p>
      <p>
        Unless otherwise specified, the InCircle website and associated services are for your personal and non-commercial use. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products or services obtained from the site.
      </p>
      <p>
        <b>Privacy Policy</b>
      </p>
      <p>
        InCircle believes strongly in protecting visitor privacy. Please refer to our <Link to="/privacy/">PRIVACY POLICY</Link> for information about how we use and collect information.
      </p>
      <p>
        <b>External Sites</b>
      </p>
      <p>
        This site contains links to other sites on the Internet that are owned and operated by third-party vendors and other third parties (the "External Sites"). InCircle is providing these links to you only as a convenience, and the inclusion of any link does not necessarily imply endorsement by InCircle of the site or any association with its operators. InCircle’s <Link to="/privacy/">PRIVACY POLICY</Link> does not apply to external sites. You acknowledge that InCircle is not responsible for the availability of, or the content located on or through, any External Site. You should contact the site administrator or Webmaster for those External Sites if you have any concerns regarding the content located on the External Sites.
      </p>
      <p>
        <b>Content Submitted to InCircle</b>
      </p>
      <p>
        InCircle does not claim ownership of the materials you provide to us (including feedback and suggestions) or post, upload, input or submit to the website or the InCircle server and through its associated services ("Submissions"). However, in order for us to make the website function in a meaningful way and to provide related services to you, you must agree that by posting, uploading, inputting, providing or submitting your Submission you automatically grant (or warrant that the owner of such rights has expressly granted) us, our affiliated companies and necessary sublicensees permission to use your Submission including, without limitation, the rights to: copy, distribute, transmit, reproduce, edit, translate and reformat your Submission.
      </p>
      <p>
        You understand that the technical processing and transmission of the service, including your Submission, may involve transmissions over various networks and changes to conform and adapt to technical requirements of connecting networks or devices.
      </p>
      <p>
        <b>Warranty Disclaimers</b>
      </p>
      <p>
        Although InCircle is working hard to ensure that the information provided on the website is accurate and up-to-date, due to the risk that the information may be compromised by software or procedural errors, InCircle does not guarantee the accuracy or completeness of the information provided on this site. This site could contain typographical errors or technical inaccuracies. InCircle reserves the right to add to, change or delete its content or any part thereof without notice.
      </p>
      <p>
        Additionally, this site may contain information provided by third parties. InCircle makes no representation or warranty regarding the accuracy, truth, quality, suitability or reliability of such information. InCircle is not responsible for any errors, omissions, or inaccuracies contained in any information provided by such third parties. Visitors should exercise care in evaluating such information and opinions.
      </p>
      <p>
        InCircle does not promise the InCirclereview.com site (the "Site") will be error-free or uninterrupted, or that the site will provide specific results from your use of any content, search, or link on them. The Site and all content within them is delivered on an "AS IS" and "AS AVAILABLE" basis. InCircle does not warrant or represent that files you download from the Sites will be free of viruses or other harmful features. Under no circumstances will InCircle be liable in any way for any information or content, including, but not limited to, for any errors or omissions in any information or content, or for any loss or damage of any kind incurred as a result of the use of any information or content, posted, emailed or otherwise transmitted via the services associated with this site.
      </p>
      <p>
        INCIRCLE DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT.
      </p>
      <p>
        <b>Limitation of Liability</b>
      </p>
      <p>
        INCIRCLE has no control over your use of the content provided on this website and no knowledge of the specific or unique circumstances under which its content may be used by you. INCIRCLE can accept no liability to any person for any data or information on the website. INCIRCLE SHALL NOT BE LIABLE TO ANY PERSON (INCLUDING BUT NOT LIMITED TO YOU AND PERSONS TREATED BY YOU OR ON YOUR BEHALF) INCLUDING BUT NOT LIMITED TO LOSSES FOR TORT, PERSONAL INJURY, MEDICAL MALPRACTICE OR PRODUCT LIABILITY.
      </p>
      <p>
        UNDER NO CIRCUMSTANCES, INCLUDING BUT NOT LIMITED TO BREACH OF CONTRACT, TORT, OR NEGLIGENCE, WILL INCIRCLE OR ITS PARENTS, SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS OR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, TRANSMITTING, OR DISTRIBUTING THIS SITE BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES (INCLUDING LOST PROFITS) THAT ARISE OUT OF OR ARE RELATED TO YOUR USE OF OR INABILITY TO USE THIS SITE OR ANY CONTENT PROVIDED BY OR THROUGH THIS SITE, OR RESULTING FROM UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA, OR OTHER INFORMATION THAT IS SENT OR RECEIVED. IN NO EVENT SHALL INCIRCLE’S AGGREGATE LIABILITY TO YOU FOR ANY LOSS, DAMAGE, OR CLAIM RELATED TO OR ARISING OUT OF THE SITE, ITS CONTENT OR RELATED SERVICES EXCEED THE TOTAL AMOUNTS, IF ANY, PAID BY YOU TO INCIRCLE FOR ACCESSING THIS SITE OR USING ANY ASSOCIATED SERVICES.
      </p>
      <p>
        As between you and InCircle, you hereby assume full responsibility for insuring the appropriateness of using and relying upon the information in view of all attendant circumstances, indications and contradictions.
      </p>
      <p>
        <b>International Use of This Website</b>
      </p>
      <p>
        The information presented here is intended for use in the United States only. InCircle operates this site from its offices within the United States of America. Regulatory requirements, regulations, laws, and distribution of information about medical products may vary from country to country and InCircle makes no representation that contents in the site are appropriate or available for use in other locations. Nothing herein should be construed as a solicitation or promotion for any product or for an indication for any product which is not authorized by the laws and regulations of the country where the reader resides. Those who choose to access this site from outside the United States do so on their own initiative and are responsible for compliance with applicable laws of their jurisdictions. The conditions and qualifications contained herein will be construed in accordance with the laws of California, United States of America, without regard to its conflict of law provisions.
      </p>
      <p>&nbsp;</p>
    </Container>
  </Index>
)

export default TermsOfUse
